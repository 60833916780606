@use "@/styles/abstracts" as *;

.pipeline-locator {
	background-color: var(--theme--background);
	color: var(--theme--text-color);
	padding: rem(60) 0;

	@include breakpoint($md) {
		padding: rem(80) 0;
	}

	@include breakpoint($lg) {
		padding: rem(96) 0;
	}

	&--dawn {
		@include theme-dawn;
	}
	&--dusk {
		@include theme-dusk;
	}
	&--midnight {
		@include theme-midnight;
	}

	&__inner {
		@include container($container-std);
	}
}
