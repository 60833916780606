@use "@/styles/abstracts" as *;

.product-placeholder {
	text-align: center;
	color: white;
	@include body-1;
	display: grid;
	justify-items: center;
	padding: rem(60) 0;

	@include breakpoint($md) {
		padding: rem(80) 0;
	}

	@include breakpoint($lg) {
		padding: rem(96) 0;
	}

	svg {
		color: $color-purple--primary;
	}
}
