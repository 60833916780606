@use "@/styles/abstracts" as *;
@use "sass:color";

.table-block {
	padding: rem(60) 0;

	@include breakpoint($md) {
		padding: rem(80) 0;
	}

	@include breakpoint($lg) {
		padding: rem(96) 0;
	}

	--alternate: #{color.scale($color-orange--100, $lightness: 40%)};

	&--dawn {
		@include theme-dawn;
		background-color: var(--theme--background);
		color: var(--theme--text-color);
	}
	&--dusk {
		@include theme-dusk;
		background-color: var(--theme--background);
		color: var(--theme--text-color);
	}
	&--midnight {
		@include theme-midnight;
		background-color: var(--theme--background);
		color: var(--theme--text-color);
		--alternate: #{$color-orange--100};
	}

	&__wrapper {
		@include container($container-std);
		display: grid;
		row-gap: rem(24);

		@include breakpoint($md) {
			row-gap: rem(32);
		}
		@include breakpoint($lg) {
			row-gap: rem(40);
		}
	}

	&__intro {
		text-align: center;
		display: grid;
		justify-items: center;
		gap: rem(12);

		@include breakpoint($md) {
			gap: rem(16);
		}

		@include breakpoint($md) {
			gap: rem(24);
		}
	}
	&__header {
		display: grid;
		justify-items: inherit;
		@include breakpoint($md) {
			display: flex;
			align-items: baseline;
			justify-content: center;
		}
	}

	&__flourish {
		order: -1;
		margin-bottom: rem(16);
		width: rem(75);
		height: rem(75);
		@include breakpoint($md) {
			order: 0;
			margin: 0;
			width: rem(90);
			height: rem(90);
			// margin-right: rem(-90);
		}

		@include breakpoint($lg) {
			width: rem(110);
			height: rem(110);
			// margin-right: rem(-110);
		}
	}

	&__heading {
		@include h3;
		margin: 0;
	}

	&__body {
		@include body-2;
	}

	&__table {
		$table: &;
		@include theme-dawn;
		color: var(--theme--text-color);

		overflow-x: auto;
		max-width: calc(100vw - (var(--container-gutter) * 2));
		border-radius: 24px;

		table {
			min-width: 100%;
			border: 1px solid var(--alternate);
			border-spacing: 0;
			overflow: hidden;
			border-radius: 24px;

			th {
				@include body-1;
				font-weight: $fw-bold;
			}
			td {
				@include body-2;
			}

			th,
			td {
				background: #fff;
				text-align: left;
				border-bottom: 1px solid var(--alternate);
				white-space: pre-wrap;
				vertical-align: top;
				padding: rem(14);
				min-width: 36vw;
				@include breakpoint($sm) {
					min-width: auto;
				}
				@include breakpoint($md) {
					padding: rem(16);
				}
				@include breakpoint($md) {
					padding: rem(19) rem(16) rem(18);
				}
			}
			tbody {
				tr:last-of-type {
					th,
					td {
						border-bottom: none;
					}
				}
			}
		}
		&--alternating {
			table {
				border: none;
			}

			th,
			td {
				border: none;
			}

			tbody tr {
				&:nth-child(odd) {
					th,
					td {
						background: $color-white;
					}
				}
				&:nth-child(even) {
					th,
					td {
						background: var(--alternate);
					}
				}
			}
			&:is(#{$table}--row-header) {
				thead th {
					background: $color-white;
				}
				tbody tr {
					&:nth-child(odd) {
						th,
						td {
							background: var(--alternate);
						}
					}
					&:nth-child(even) {
						th,
						td {
							background: $color-white;
						}
					}
				}
			}
		}
	}
}
