@use "@/styles/abstracts" as *;

.header {
	@include theme-midnight;
	background-color: var(--theme--background);
	color: var(--theme--text-color);
	position: sticky;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 100;

	&__logo-link {
		text-decoration: none;
		color: $color-purple--primary;
	}

	&__logo {
		height: rem(40);
	}

	&__secondary,
	&__primary {
		padding-left: calculated-container-margin($container-std);
		padding-right: calculated-container-margin($container-std);
	}

	&__secondary {
		padding-top: rem(24);
		padding-bottom: rem(16);
		display: flex;
		align-items: flex-end;

		@include breakpoint(0, $xl - 1px) {
			display: none;
		}

		&-list {
			list-style: none;
			display: flex;
			align-items: center;
			gap: rem(24);
			margin: 0;
			padding: 0;
			margin-left: auto;
		}

		&-link {
			@include body-3;
			text-decoration: none;
			transition: color $timing-short $ease-std;
			color: $color-white;
			border-radius: 1px;
			@include hover-focus-visible {
				color: $color-orange--primary;
			}

			&:focus-visible {
				outline: 1px solid $color-orange--primary;
				outline-offset: 0.5em;
			}

			&--large {
				@include body-2;
			}
		}
	}

	&__primary {
		border-top: 1px solid $color-purple--800;
		border-bottom: 1px solid $color-purple--800;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-top: rem(10);
		padding-bottom: rem(10);
		@include breakpoint($xl) {
			padding-top: rem(16);
			padding-bottom: rem(24);
		}
	}

	&__actions,
	&__mobile-actions {
		display: flex;
		align-items: center;
		gap: rem(16);
	}

	&__actions {
		@include breakpoint(0, $xl - 1px) {
			display: none;
		}
	}

	&__mobile-actions {
		@include breakpoint($xl) {
			display: none;
		}
	}

	&__hamburger {
		@include button-reset;
		display: flex;
		align-items: center;
		justify-content: center;
		color: $color-white;
		cursor: pointer;
		height: rem(32);
		width: rem(32);

		@include hover-focus-visible {
			color: $color-orange--primary;
		}

		svg {
			width: rem(24);
			height: auto;
		}
	}

	&__active-region {
		text-decoration: underline;
	}
}
