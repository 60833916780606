@use "@/styles/abstracts" as *;

.quick-links {
	@include container($container-std);
	@include theme-dawn;
	color: var(--theme--text-color);

	&__inner {
		display: flex;
		border-radius: rem(20);
		background-color: var(--theme--background);
		padding: rem(24);
		justify-content: center;
		align-items: center;
		gap: rem(24);
		flex-direction: column;
		flex-wrap: wrap;

		@include breakpoint($md) {
			border-radius: rem(40);
			flex-direction: row;
			gap: rem(32);
		}
		@include breakpoint($lg) {
			gap: rem(40);
		}
	}
}

.quick-link {
	position: relative;
	color: inherit;
	text-decoration: none;
	display: inline-flex;
	align-items: center;
	gap: rem(8);
	@include body-2;

	&::after {
		content: "";
		position: absolute;
		top: 100%;
		left: 50%;
		transform: translateX(-50%);
		width: 0;
		height: 2px;
		background: var(--theme--button-fill-accent);
		border-radius: 100vw;
		margin-top: rem(5);
		transition: width $timing $ease-std;
	}

	@include hover-focus-visible {
		&::after {
			width: 100%;
		}
	}

	&__icon {
		color: var(--theme--button-fill-accent);
	}
}
