@use "@/styles/abstracts" as *;

.footer {
	@include theme-midnight;
	background-color: var(--theme--background);
	color: var(--theme--text-color);
	display: grid;
	padding-inline: calculated-container-margin($container-std);
	padding-block: rem(48);
	gap: rem(24);
	@include breakpoint($md) {
		padding-block: rem(56);
	}
	@include breakpoint($lg) {
		padding-block: rem(64);
		gap: rem(32);
	}

	&__logo-link {
		justify-self: flex-start;
		color: $color-purple--primary;
		height: rem(40);
		width: auto;
		display: inline-block;
		> svg {
			height: 100%;
		}
	}

	&__mobile-menu {
		@include breakpoint($md) {
			display: none;
		}
		margin: 0;
		padding: 0;
		list-style: none;
		display: grid;
		gap: rem(30);
	}

	&__desktop-menu {
		@include breakpoint(0, $md - 1px) {
			display: none;
		}
		margin: 0;
		padding: 0;
		list-style: none;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: rem(24) rem(16);

		@include breakpoint($lg) {
			grid-template-columns: repeat(5, 1fr);
			gap: rem(32) rem(24);
		}
		@include body-2;
	}

	&__separator {
		width: 100%;
		border: none;
		border-top: 1px solid $color-purple--800;
	}

	&__secondary {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		gap: inherit;
		@include body-3;

		&-menu {
			display: grid;
			gap: rem(16);

			@include breakpoint($md) {
				display: flex;
				gap: rem(24);
			}
			@include breakpoint($lg) {
				gap: rem(32);
			}
		}
	}

	&__link {
		color: $color-white;
		text-decoration: none;
		transition: $timing-short $ease-std;

		@include hover-focus-visible {
			color: $color-orange--primary;
		}
	}

	&__info {
		@include body-3;
	}

	&__list {
		display: grid;
		list-style: none;
		margin: 0;
		padding: 0;
		gap: rem(12);

		> li:first-child {
			padding-top: rem(16);
		}
		@include breakpoint($md) {
			gap: rem(16);

			> li:first-child {
				padding-top: rem(24);
			}
		}
		@include breakpoint($lg) {
			gap: rem(24);

			> li:first-child {
				padding-top: rem(32);
			}
		}
	}

	&__heading {
		@include body-2;
		font-weight: $fw-bold;
		margin: 0;
	}
}

.footer-accordion {
	&__header {
		text-align: left;
		display: flex;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;
		transition: color $timing-short $ease-std;

		@include hover-focus-visible {
			color: $color-orange--primary;
		}

		svg {
			width: rem(13);
			padding: 0 rem(4);
			box-sizing: content-box;
			height: auto;
			transition: transform $timing-short $ease-std;
		}

		[aria-expanded="true"] > & {
			svg {
				transform: rotate(180deg);
			}
		}
	}

	&__link {
		@include body-2;
	}
}
