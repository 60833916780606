@use "./pseudo" as *;

@mixin hover {
	// Do not use hover effect on mobile
	@media (hover: hover) {
		&:hover {
			@content;
		}
	}
}

@mixin hover-focus {
	@include hover {
		@content;
	}
	&:focus {
		@content;
	}
}

@mixin hover-focus-visible {
	@include hover {
		@content;
	}
	&:focus-visible {
		@content;
	}
}

@mixin hover-focus-within {
	@include hover {
		@content;
	}
	&:focus-within {
		@content;
	}
}
