@use "@/styles/abstracts" as *;

.product-lookup {
	width: 100%;
	max-width: rem(450);
	display: grid;
	justify-items: flex-start;
	gap: rem(20);
	text-align: left;
}

.errors {
	padding: 1em 1em 0.8em;
	border-radius: 10px;
	background-color: $color-orange--200;
	color: $color-orange--800;
	display: flex;
	gap: 0.8em;

	&__icon {
		flex: none;
	}

	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		display: grid;
		gap: 0.5em;
	}
}

.promo {
	position: relative;
	--input-icon: #{rem(32)};
	--input-clear: #{rem(50)};
	display: grid;
	align-items: center;
	gap: rem(16);
	width: 100%;

	@include breakpoint($md) {
		grid-template-columns: auto 1fr;
	}

	&__input-wrapper {
		@include theme-dawn;
		display: grid;
		grid-template-columns: var(--input-icon) 1fr var(--input-clear);

		&--hidden {
			@include breakpoint(0, $md - 1px) {
				display: none;
			}
			opacity: 0;
			appearance: hidden;
			pointer-events: none;
		}
	}

	&__field {
		grid-column: 1/-1;
		grid-row: 1;

		--input-left: var(--input-icon);
		--input-right: var(--input-clear);
	}

	&__icon {
		grid-column: 1;
		grid-row: 1;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		pointer-events: none;
		position: relative;
		z-index: 1;
		color: $color-text;
		margin-right: rem(-10);
	}

	&__clear {
		z-index: 0;
		grid-column: 3;
		grid-row: 1;
		padding: 0;
		margin: 0;
		appearance: none;
		background: none;
		border: none;
		color: $color-text;
		text-align: left;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;

		@include hover-focus-visible {
			color: $color-orange--primary;
		}
	}
}
