@use "@/styles/abstracts" as *;

.address {
	display: grid;
	gap: rem(8);
	width: 100%;
	position: relative;
	@include theme-dawn;

	--input-icon: #{rem(32)};
	--input-clear: #{rem(50)};

	&__label {
		@include label-style;
	}

	&__input-wrapper {
		display: grid;
		// position: relative;
		grid-template-columns: var(--input-icon) 1fr var(--input-clear);
	}

	&__icon {
		grid-column: 1;
		grid-row: 1;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		pointer-events: none;
		position: relative;
		z-index: 1;
		color: $color-text;
		margin-right: rem(-10);
	}

	&__input {
		grid-column: 1/-1;
		grid-row: 1;
		--input-left: var(--input-icon);
		--input-right: var(--input-clear);
		@include input-style;
		@include body-2;

		&--error {
			--error-color: #{$color-invalid};
		}

		&--suggestions-open {
			border-color: var(--theme--input-focus-border-accent);
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;

			&:hover {
				border-color: var(--theme--input-focus-border-accent);
			}
		}
	}

	&__clear {
		grid-column: 3;
		grid-row: 1;
		padding: 0;
		margin: 0;
		appearance: none;
		background: none;
		border: none;
		color: $color-text;
		text-align: left;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;

		@include hover-focus-visible {
			color: $color-orange--primary;
		}
	}

	&__suggestion-list {
		display: none;
		list-style: none;
		position: absolute;
		top: 100%;
		margin: 0;
		padding: 0;
		@include dropdown-style;
		border-top: none;
		z-index: 9999999;

		&--open {
			display: block;
		}

		&::before {
			content: none;
		}
	}

	&__suggestion {
		@include dropdown-item-style;
		font-size: rem(18);
		display: flex;
		white-space: pre-wrap;
		em {
			font-style: normal;
			font-weight: $fw-black;
		}

		&--highlighted {
			@include dropdown-item-highlighted-style;
		}

		&-icon {
			flex: none;
			align-items: center;
			margin-right: 0.25em;
		}
	}
}
