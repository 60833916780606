@use "@/styles/abstracts" as *;

.nmi-address-select {
	display: grid;

	gap: rem(32);
	@include breakpoint($lg) {
		gap: rem(40);
	}

	&__body {
		max-width: rem(780);
		text-align: center;
		margin: 0 auto;
		display: grid;
		@include body-1;
		font-weight: $fw-regular;

		gap: rem(12);
		@include breakpoint($md) {
			gap: rem(16);
		}
		@include breakpoint($lg) {
			gap: rem(24);
		}

		> * {
			margin: 0;
		}
	}
	&__heading {
		margin: 0;

		@include h4;
	}

	&__card {
		display: grid;
		@include theme-dawn;
		background-color: var(--theme--background);
		color: var(--theme--text-color);
		border-radius: 24px 24px 64px 24px;
		padding: rem(24);
		@include breakpoint($md) {
			padding: rem(32);
		}
		@include breakpoint($lg) {
			padding: rem(40);
		}
	}

	&__card-header {
		display: flex;
		justify-content: space-between;
		@include label-style;
	}

	&__card-heading {
		font: inherit;
		@include body-2;
	}

	&__nmi-header,
	&__nmi {
		display: inline-block;
		@include breakpoint($md) {
			width: rem(170);
		}
	}

	&__nmi-header {
		display: none;

		@include breakpoint($md) {
			display: block;
		}
	}

	& &__radio-group {
		gap: 0;
		display: grid;
	}

	& &__radio-group-option {
		flex: 1 1 auto;
		padding: rem(12) 0;
		border-top: 1px solid $color-orange--200;
		display: grid;
		grid-template-columns: auto 1fr;

		&:last-child {
			border-bottom: 1px solid $color-orange--200;
		}
	}

	& &__radio-group-label {
		display: grid;
		justify-content: space-between;
		@include body-2;
		font-weight: $fw-bold;
		gap: 0.75em;

		@include breakpoint($md) {
			display: flex;
		}
	}

	&__nmi-label {
		@include breakpoint($md) {
			@include hide;
		}
	}
	&__radio-group-label &__nmi {
		font-weight: $fw-regular;
		letter-spacing: 2px;
	}

	&__submit {
		margin: rem(24) auto 0;
	}
}
