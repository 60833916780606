@use "@/styles/abstracts" as *;

.hero-homepage {
	@include theme-dusk;
	// background-color: $color-purple--dark;
	color: var(--theme--text-color);
	display: grid;
	position: relative;
	row-gap: rem(24);
	padding-top: rem(48);
	padding-bottom: rem(48);
	width: 100vw;
	min-height: rem(486);
	align-items: center;

	padding-left: calculated-container-margin($container-std);
	padding-right: calculated-container-margin($container-std);

	@include breakpoint($md) {
		--container-gutter: #{rem(56)};
		display: flex;
		padding-top: rem(56);
		padding-bottom: rem(56);
		padding-right: rem(32);
	}

	@include breakpoint($lg) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		padding: 0;
	}

	&__content {
		z-index: 1;
		flex: 1 1;

		@include body-2;
		display: grid;
		gap: rem(32);

		@include breakpoint($md) {
			padding-right: rem(24);
		}

		@include breakpoint($md) {
			padding-right: rem(32);
		}

		@include breakpoint($lg) {
			padding-left: calculated-container-margin($container-std);
			padding-top: rem(48);
			padding-bottom: rem(48);
			padding-right: 0;
		}
	}

	&__heading {
		margin: 0;
	}

	&__image {
		order: -1;
		z-index: 2;
		justify-self: flex-start;
		align-self: stretch;
		width: rem(187);
		height: rem(187);
		position: relative;

		@include breakpoint($md) {
			margin: 0;
			order: 0;
			width: rem(380);
			height: rem(380);
			top: -100px;
			right: -100px;
		}

		@include breakpoint($lg) {
			position: relative;
			left: 0;
			top: 0;
			height: rem(728);
			width: 100%;
			z-index: -2;
		}

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			border-radius: 100vw;
			object-fit: cover;
			@include breakpoint($lg) {
				border-radius: 0;
			}
		}
	}

	&__squiggle {
		pointer-events: none;
		position: absolute;
		z-index: -1;
		display: flex;
		top: 50%;
		left: 50%;

		width: 1166px;
		height: 2658px;

		@include breakpoint($md) {
			width: 1218px;
			height: 2726px;
		}

		@include breakpoint($lg) {
			width: 1272px;
			height: 2794px;
		}

		&-inner {
			position: relative;
			height: 100%;
			width: 100%;

			left: -791px;
			top: -464px;

			@include breakpoint($md) {
				left: -750px;
				top: -1430px;
			}

			@include breakpoint($lg) {
				left: -955px;
				top: -1430px;
			}
		}

		svg {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			width: 100%;
			height: 100%;
		}
	}
}
