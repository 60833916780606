@use "@/styles/abstracts" as *;

.header-menu {
	transform: none;
	transition: none;

	@include breakpoint(0, $xl - 1px) {
		position: fixed;
		display: grid;
		align-content: flex-start;
		z-index: 1000;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		overflow-y: auto;
		overflow-x: hidden;
		gap: rem(24);
		padding: rem(16) var(--container-gutter) rem(24);
		background-color: $color-purple--dark;
		grid-template-rows: auto 1fr auto;
		transform: translateX(100%);
	}

	&--mobile-expanded {
		@include breakpoint(0, $xl - 1px) {
			transform: translateX(0%);
		}
	}

	&__mobile-header {
		display: flex;
		justify-content: flex-end;
		@include breakpoint($xl) {
			display: none;
		}
	}

	&__close {
		@include button-reset;
		padding: rem(8);
		position: relative;
		z-index: 2;
		color: $color-white;
		@include hover-focus-visible {
			color: $color-orange--primary;
		}
	}

	&__list {
		list-style: none;
		margin: 0;
		padding: 0;
		display: grid;
		margin-bottom: auto;
		margin-top: rem(-24);
		@include breakpoint($xl) {
			display: flex;
			margin: 0;
			gap: rem(28);
		}
	}

	&__item {
		& + & {
			@include breakpoint(0, $xl - 1px) {
				border-top: 1px solid $color-purple--800;
			}
		}
	}

	&__parent-wrapper {
		display: flex;
		align-items: center;
		@include breakpoint($xl) {
			position: relative;
		}
	}

	&__parent,
	&__link {
		@include body-1;
		@include breakpoint($xl) {
			@include body-3;
			height: rem(52);
		}
		text-decoration: none;
		transition: color $timing-short $ease-std;
		color: $color-white;
		border-radius: 1px;
		@include hover-focus-visible {
			color: $color-orange--primary;
		}

		&:focus-visible {
			outline: 1px solid $color-orange--primary;
			outline-offset: 0.5em;
		}
	}

	&__parent {
		background: none;
		color: inherit;
		cursor: pointer;
		border: none;
		margin: 0;
		padding: 0;
		cursor: pointer;
		display: flex;
		align-items: center;
		gap: rem(12);

		@include breakpoint(0, $xl - 1px) {
			width: 100%;
			height: auto;
			padding: rem(24) 0;
			justify-content: space-between;
			padding-right: 0.5em;

			svg {
				width: rem(12);
				height: auto;
				transform: rotate(-90deg);
			}
		}

		&--expanded {
			color: $color-orange--primary;
		}
	}

	&__footer {
		display: grid;
		gap: rem(24);

		@include breakpoint($xl) {
			display: none;
		}
	}

	&__footer-actions {
		display: flex;
		justify-content: center;
		gap: rem(16);
		> * {
			padding: rem(20);
			width: 100%;
			flex: 1 1 auto;
			max-width: rem(200);
		}
	}

	&__footer-links {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: rem(16);
	}

	&__v-bar {
		border-left: 1px solid $color-purple--800;
		height: 2em;
	}

	&__active-region {
		text-decoration: underline;
	}
}

.header-submenu {
	background: $color-purple--dark;

	@include breakpoint(0, $xl - 1px) {
		position: fixed;
		z-index: 1;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		overflow-x: hidden;
		overflow-y: auto;
		padding: rem(16) rem(24);
		display: grid;
		align-content: flex-start;
	}

	@include breakpoint($xl) {
		border: 1px solid $color-purple--800;
		border-radius: 0 0 20px 20px;
		position: absolute;
		top: 100%;
		left: -15px;
		margin-top: rem(24);
		max-height: 70dvh;
		overflow-y: auto;
		min-width: rem(252);

		&__back,
		&__heading {
			display: none !important;
		}
		&__item:first-child &__link {
			padding-top: rem(16);
		}
		&__item:last-child &__link {
			padding-bottom: rem(16);
		}

		&__link {
			padding: rem(8) rem(16);
		}
	}

	&__back {
		@include button-reset;
		width: rem(32);
		height: rem(32);
		display: flex;
		align-items: center;
		justify-content: center;
		color: $color-white;
		margin-bottom: rem(32);
		position: relative;
		z-index: 2;
		@include hover-focus-visible {
			color: $color-orange--primary;
		}
	}

	&__heading {
		@include pre-heading;
		margin-bottom: rem(12);
	}

	&__list {
		list-style: none;
		padding: 0;
		margin: 0;
		display: grid;
	}

	&__link {
		@include body-1;
		@include breakpoint($xl) {
			@include body-3;
		}
		display: block;
		color: inherit;
		text-decoration: none;
		font-weight: $fw-regular;
		--hover-opacity: 0;

		@include hover-focus-visible {
			outline: none;
			--hover-opacity: 1;
			@include breakpoint(0, $xl - 1px) {
				color: $color-orange--primary;
			}
		}

		&-inner {
			padding: rem(12) 0;
			svg {
				display: none;
			}

			@include breakpoint($xl) {
				padding: rem(8) rem(16);
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 0.5em;
				position: relative;
				@include pseudo-border(1px solid $color-orange--primary) {
					transition: opacity $timing-short $ease-std;
					opacity: var(--hover-opacity);
					border-radius: 10px;
				}
				svg {
					display: inline-block;
					flex: none;
					transition: opacity $timing-short $ease-std;
					opacity: var(--hover-opacity);
					transform: scaleX(-1);
					width: rem(13);
				}
			}
		}
	}
}
