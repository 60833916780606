@use "@/styles/abstracts" as *;


.loading {
	@include body-1;
	@include theme-midnight;
	color: var(--theme--text-color);
	text-align: center;
	display: grid;
	justify-items: center;
	padding-block: rem(60);

	@include breakpoint($md) {
		padding-block: rem(80);
	}

	@include breakpoint($lg) {
		padding-block: rem(96);
	}
}

.product-results {
	@include theme-midnight;
	@include container($container-std);
	color: var(--theme--text-color);
	@include body-1;
	display: grid;
	padding-block: rem(60);
	row-gap: rem(24);

	@include breakpoint($md) {
		padding-block: rem(80);
		row-gap: rem(32);
	}

	@include breakpoint($lg) {
		padding-block: rem(96);
		row-gap: rem(40);

		grid-template-columns: 1fr 11fr 1fr;
		> * {
			grid-column: 2;
		}
	}

	&__message {
		display: flex;
		gap: 0.5em;
		@include body-2;

		&--success {
			color: yellow;
		}

		&--warn &-icon {
			color: $color-orange--400;
		}
	}
}
