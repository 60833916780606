@use "@/styles/abstracts" as *;

.draft {
	--draft-color: #ffc400;
	--border-image: repeating-linear-gradient(
			45deg,
			var(--draft-color),
			var(--draft-color) 6px,
			black 6px,
			black 12px
		)
		1;
	position: fixed;
	z-index: 90990909;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border: 4px solid;
	border-image: var(--border-image);
	pointer-events: none;

	&__tag {
		position: absolute;
		bottom: -4px;
		left: 50%;
		color: var(--draft-color);
		background-color: black;
		padding: 0.75em 1.5em;
		border: 4px solid var(--draft-color);
		border-radius: 0.5em 0.5em 0 0;
		border-bottom: none;
		transform: translateX(-50%);
		font-weight: $fw-medium;
	}
}
