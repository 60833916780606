@use "@/styles/abstracts" as *;

.not-found {
	@include theme-dawn;
	background-color: var(--theme--background);
	display: grid;

	&__image {
		height: 38vh;
		position: relative;
		> img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__content {
		display: grid;
		justify-items: center;
		text-align: center;
		padding-inline: calculated-container-margin($container-std);
		padding-block: rem(64);
		gap: rem(16);
		@include breakpoint($md) {
			padding-block: rem(80);
			gap: rem(18);
		}

		@include breakpoint($lg) {
			padding-block: rem(96);
			gap: rem(24);
		}
	}

	&__heading {
		@include h3;
		margin: 0;
	}

	&__body {
		@include body-1;
		margin: 0;
	}
}
