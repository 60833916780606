@use "@/styles/abstracts" as *;

.button-wrapper {
	text-decoration: none !important;
	margin: 0;
	padding: 0;
	color: currentColor;
	border: none;
	display: inline-block;
	cursor: pointer;
	background: none;
	text-rendering: inherit;

	outline: none !important;

	// All defaults to light theme
	--color: var(--theme--button-color, #{$color-purple--dark});
	--fill-accent: var(--theme--button-fill-accent, #{$color-orange--primary});
	--border-accent: var(--theme--button-border-accent, #{$color-purple--dark});
	--active-fill-accent: var(--theme--button-active-fill-accent, #{$color-orange--700});
	--active-border-accent: var(--theme--button-active-border-accent, #{$color-orange--primary});
	--disabled-color: var(--theme--button-disabled-color, #{$color-grey--700});
	--disabled-fill: var(--theme--button-disabled-fill, #{$color-grey--400});

	&:disabled {
		pointer-events: none;
		cursor: auto;
	}
}

.button {
	$button: &;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-size: rem(16);
	line-height: rem(20);
	letter-spacing: rem(1);
	text-transform: uppercase;
	font-weight: 700;
	transition: $timing-short $ease-std;
	color: var(--color);
	gap: rem(8);
	white-space: nowrap;

	.button-wrapper:focus-visible > & {
		outline: 3px solid var(--active-border-accent);
		outline-offset: 4px;
	}

	&__arrow-wrapper {
		height: 1em;
		display: inline-flex;
		align-items: center;
	}
	&__arrow {
		transition: $timing-short $ease-std;
	}

	@include hover-focus-visible {
		#{$button}__arrow {
			transform: translateX(3px);
		}
	}

	&--primary {
		--color: #{$color-white};
		background-color: var(--fill-accent);
	}

	&--primary,
	&--secondary {
		padding: rem(16) rem(24);
		border-radius: rem(26);
	}

	&--primary {
		background-color: var(--fill-accent);

		@include hover-focus-visible {
			background-color: var(--active-fill-accent);
			border-radius: rem(20);
		}
	}

	&--secondary {
		position: relative;
		@include pseudo-border(2px solid var(--border-accent));

		@include hover-focus-visible {
			&::before {
				border-color: var(--active-border-accent);
			}
			border-radius: rem(20);
		}
	}

	&--tertiary {
		position: relative;
		padding: rem(8) 0;
		&::before {
			content: "";
			height: 2px;
			position: absolute;
			top: 100%;
			width: 100%;
			left: 0;
			border-radius: 100vw;
			background-color: var(--fill-accent);
			transition: $timing-short $ease-std;
		}
		@include hover-focus-visible {
			&::before {
				background-color: var(--active-fill-accent);
			}
			svg {
				color: var(--fill-accent);
			}
		}
	}

	.button-wrapper:disabled > & {
		--color: var(--disabled-color);
		--fill-accent: var(--disabled-fill);
		--border-accent: var(--disabled-color);
		--active-fill-accent: var(--disabled-fill);
		--active-border-accent: var(--disabled-color);
	}
}
