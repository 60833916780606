@use "@/styles/abstracts" as *;

.hero {
	&--dawn {
		@include theme-dawn;
		background-color: var(--theme--background);
		color: var(--theme--text-color);
		--breadcrumb-color: #{$color-grey--700};
	}

	&--dusk {
		@include theme-dusk;
		background-color: var(--theme--background);
		color: var(--theme--text-color);
		--breadcrumb-color: #{$color-purple--100};
	}

	&--midnight {
		@include theme-midnight;
		background-color: var(--theme--background);
		color: var(--theme--text-color);
		--breadcrumb-color: #{$color-purple--100};
	}

	position: relative;
	display: grid;
	align-content: center;
	padding-top: rem(56);
	padding-bottom: rem(56);
	width: 100vw;
	align-items: center;
	grid-template-areas: "breadcrumb" "image" "content";

	padding-inline: calculated-container-margin($container-std);

	@include breakpoint($md) {
		padding-right: calc(calculated-container-margin($container-std) - var(--container-gutter));
		padding-top: rem(80);
		padding-bottom: rem(80);
		grid-template-columns: 1fr 1fr;
		grid-template-areas:
			"breadcrumb image"
			"content image";
	}

	@include breakpoint($lg) {
		grid-template-columns: 5fr 7fr;
		padding-top: rem(96);
		padding-bottom: rem(96);
	}

	&--centered {
		justify-items: center;
		text-align: center;
		grid-template-columns: auto;
		grid-template-areas: "breadcrumb" "content";
		@include breakpoint($md) {
			grid-template-columns: auto;
			grid-template-areas: "breadcrumb" "content";
		}
		@include breakpoint($lg) {
			grid-template-columns: auto;
		}
	}

	&__content {
		grid-area: content;
		display: grid;
		justify-items: inherit;
		gap: rem(18);
		@include breakpoint($md) {
			gap: rem(24);
		}
		@include breakpoint($lg) {
			gap: rem(32);
		}

		@include breakpoint($md) {
			padding-right: var(--container-gutter);
		}
	}

	&__breadcrumb {
		grid-area: breadcrumb;
		@include body-2;
		font-weight: $fw-bold;
		color: var(--breadcrumb-color);
		display: flex;
		flex-wrap: wrap;
		gap: rem(14);
		align-items: baseline;
		margin-bottom: rem(32);

		@include breakpoint($lg) {
			margin-bottom: rem(40);
		}
	}

	&__heading {
		@include h2;
		margin: 0;
	}

	&__body {
		@include body-2;
		margin: 0;
		margin-top: -1em;
	}

	&__image {
		grid-area: image;
		z-index: 2;
		justify-self: flex-start;
		align-self: stretch;
		width: rem(187);
		height: rem(187);
		position: relative;
		margin-bottom: rem(32);

		--right-offset: 0px;

		img {
			position: absolute;
			width: calc(100% + var(--right-offset));
			height: 100%;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			border-radius: 100vw;
			object-fit: cover;
			@include breakpoint($md) {
				border-radius: 0;
			}
		}

		@include breakpoint($md) {
			--right-offset: -342px;
			grid-area: auto;
			position: absolute;
			right: var(--right-offset);
			width: 86vw;
			top: 0;
			height: 100%;
			z-index: 0;
			margin: 0;

			mask-image: url("/mask-header.svg");
			mask-size: cover;
			mask-position: center center;
			mask-repeat: no-repeat;
		}
		@include breakpoint($lg) {
			--right-offset: -395px;
			width: 83vw;
		}
		@include breakpoint($xl) {
			--right-offset: -300px;
			width: 68vw;
		}
		@include breakpoint($xxl) {
			--right-offset: -340px;
			width: 63vw;
		}
	}
}
