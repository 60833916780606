.material-icon {
	font-family: Material Symbols Rounded;
	font-weight: normal;
	font-style: normal;
	line-height: 1;
	font-size: 1.5rem;
	max-width: 1em;
	max-height: 1em;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	-webkit-font-feature-settings: "liga";
	-webkit-font-smoothing: antialiased;
}
