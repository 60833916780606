@use "@/styles/abstracts" as *;

.emergency {
	@include theme-dusk;
	background-color: $color-warning;
	position: relative;
	z-index: 1;

	&__inner {
		@include container($container-std);
		padding-block: rem(8);
		@include breakpoint($sm) {
			padding-block: rem(12);
		}
		@include breakpoint($md) {
			padding-block: rem(16);
		}

		display: grid;
		align-items: flex-start;
		grid-template-columns: 1fr auto;
	}

	&__content-wrapper {
		display: flex;
		flex-wrap: wrap;
		gap: rem(8) rem(16);
		position: relative;
		align-items: center;
	}

	&__content {
		display: flex;
		gap: rem(8);
		display: inline-flex;
		align-items: center;
	}

	&__message {
		@include body-3;
	}

	&__link {
		display: inline-flex;
		align-items: center;
		color: inherit;
		font-weight: $fw-bold;
		text-decoration: none;

		&-text {
			text-decoration: underline;
		}

		&-arrow {
			text-decoration: none;
		}
	}

	&__dismiss {
		box-sizing: content-box;
		display: flex;
		align-items: center;
		justify-content: center;
		height: rem(24);
		width: rem(24);
		padding: rem(0);
		// margin: rem(-8) rem(-8) rem(-8) 0;
		appearance: none;
		background: none;
		border: none;
		color: inherit;
		cursor: pointer;
	}
}
